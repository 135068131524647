<template>
    <div class="publish-apartment" @click.stop="selectclick">
        <div class="top-bar bg-white">
<!--          @blur="selectclick"-->
            <el-select  ref="select" v-model="formSearch.uuid" placeholder="请选择小区" filterable clearable @change="handleChange2"
                       size="small">
                <el-option v-for="item in communityList" :key="item.uuid" :label="item.name" :value="item.uuid"/>
            </el-select>
          <el-select  ref="select" v-model="formSearch.status" placeholder="请选择出租状态" filterable clearable
                      size="small">
            <el-option v-for="item in statusList" :key="item.uuid" :label="item.name" :value="item.uuid"/>
          </el-select>
          <el-select  ref="select" v-model="formSearch.isLetOutRent" placeholder="请选择对外情况" filterable clearable
                      size="small">
            <el-option v-for="item in isLetOutRentList" :key="item.uuid" :label="item.name" :value="item.uuid"/>
          </el-select>
<!--          @blur="selectclick" ref="selectpublishStatus"-->
            <el-select ref="selectpublishStatus"  v-model="formSearch.publishStatus" placeholder="请选择状态" @change="handleChange"
                       style="margin: 0 7px" size="small">
                <el-option v-for="item in statuses" :key="item.id" :label="item.name" :value="item.id"/>
            </el-select>
            <el-input v-model="formSearch.keyword" placeholder="请输入关键字搜索" style="margin-right: 9px; width: 250px"
                      size="small"/>
            <el-button type="primary" @click="handleChange" size="small">查询</el-button>
            <el-button @click="formSearch.publishStatus=1;formSearch.keyword='';formSearch.uuid='';formSearch.isLetOutRent='';formSearch.status='';handleChange()" size="small">重置</el-button>
          <el-button type="primary" size="small" @click="fromSubmitFabu">批量发布</el-button>
          <el-button type="primary"  size="small" @click="fromSubmitQuxiao">批量取消</el-button>
           <!-- <el-button size="small" v-if="multipleSelection.length > 1" @click="oneClickRelease">一键发布</el-button> -->
        </div>
        <r-e-table :row-style="rowStyle" class="bg-white" ref="apartmentTableRef" :dataRequest="getApartmentTableData" :height="820"
                   :columns="apartmentColumn" :query="formSearch" @selection-change="handleSelectionChange">
            <el-table-column slot="customColumn" label="查看房型" width="350" >
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="$refs.publishRef2.layerAddEditPublishInit2(row)">
                            {{ row.publishTemplateName || "查看" }}
                        </span>
                    </div>
                </template>
            </el-table-column>
<!--            <el-table-column slot="customColumn" label="金额" width="200" v-if="formSearch.publishStatus===2">-->
<!--                <template slot-scope="{ row }">{{ (row.amount/100).toFixed(2) }}</template>-->
<!--            </el-table-column>-->
            <el-table-column slot="toolbar" label="操作" width="200">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <!--<span class="table-btn" @click="handleEditPublish(row)">查看</span>-->
                        <span class="table-btn" v-if="formSearch.publishStatus===1" @click="handlePublish(row)">发布</span>
                        <span class="table-btn" v-if="formSearch.publishStatus!==1" @click="handleCancelPublish(row)">取消</span>
                    </div>
                </template>
            </el-table-column>
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>

        <!--   查看房源详情弹框       -->
        <layer-edit-publish class="layer-edit-publish" ref="publishRef"/>

        <!--   发布弹框       -->
        <r-e-dialog title="发布" :visible="dialogVisible" show-footer @click-submit="handleSubmitPublish"
                @click-cancel="handleCancel" @close="handleCancel">
            <el-form title="" ref="formPublish" label-width="130px" size="small" :model="formPublish" :rules="rules">
                <el-form-item label="地址" prop="name">
                    <el-input disabled v-model="formPublish.name"/>
                </el-form-item>
                <el-form-item label="月租金(元/月)" prop="monthAmount">
                    <el-input type="number" v-model="formPublish.monthAmount"/>
                </el-form-item>
                <el-form-item label="季租金(元/月)" prop="seasonAmount">
                    <el-input type="number" v-model="formPublish.seasonAmount"/>
                </el-form-item>
                <el-form-item label="半年租金(元/月)" prop="halfAmount">
                    <el-input type="number" v-model="formPublish.halfAmount"/>
                </el-form-item>
                <el-form-item label="年租金(元/月)" prop="yearAmount">
                    <el-input type="number" v-model="formPublish.yearAmount"/>
                </el-form-item>
                <el-form-item label="押金(元/月)" prop="bone">
                    <el-input type="number" v-model="formPublish.bone"/>
                </el-form-item>
<!--                <el-form-item label="定金(元/月)" prop="deposit">-->
<!--                    <el-input type="number" v-model="formPublish.deposit"/>-->
<!--                </el-form-item>-->
                <el-form-item label="服务费(元/月)" prop="tipAmount">
                    <el-input type="number" v-model="formPublish.tipAmount"/>
                </el-form-item>
              <el-form-item label="保租房项目" style="width: 100%;">
                <el-select v-model="projectIdNew" placeholder="请选择" filterable style="width: 100%;">
                  <el-option v-for="item in projectList" :key="item.id" :label="item.project_name" :value="item.id"/>
                </el-select>
              </el-form-item>
              <el-form-item label="住建平台" style="width: 100%;" prop="verificationCode">
                <el-radio v-model="formPublish.verificationCode" :label="true">推送</el-radio>
                <el-radio v-model="formPublish.verificationCode" :label="false">不推送</el-radio>
                <div style="color: red">当选择推送至住建平台，仅具备有住建房源核验码才可推送</div>
              </el-form-item>
            </el-form>
        </r-e-dialog>

        <!-- 一键发布弹框 -->
        <r-e-dialog title="一键发布" :visible="OnekeyDialogVisible" show-footer @click-submit="oneClickReleaseSubmit"
                @click-cancel="handleOneCancel" @close="handleOneCancel">
            <el-form title="" ref="formOnekeyPublish" label-width="110px" size="small" :model="oneKeyFormPublish"
                     :rules="oneKeyRules">
                <el-form-item label="月租金(元/月)" prop="monthAmount">
                    <el-input type="number" v-model="oneKeyFormPublish.monthAmount" @input="$forceUpdate()"/>
                </el-form-item>
                <el-form-item label="季租金(元/月)" prop="seasonAmount">
                    <el-input type="number" v-model="oneKeyFormPublish.seasonAmount" @input="$forceUpdate()"/>
                </el-form-item>
                <el-form-item label="半年租金(元/月)" prop="halfAmount">
                    <el-input type="number" v-model="oneKeyFormPublish.halfAmount" @input="$forceUpdate()"/>
                </el-form-item>
                <el-form-item label="年租金(元/月)" prop="yearAmount">
                    <el-input type="number" v-model="oneKeyFormPublish.yearAmount" @input="$forceUpdate()"/>
                </el-form-item>

                <el-form-item label="押金(元/月)" prop="bone">
                    <el-input type="number" v-model="oneKeyFormPublish.bone" @input="$forceUpdate()"/>
                </el-form-item>
                <el-form-item label="定金(元/月)" prop="deposit">
                    <el-input type="number" v-model="oneKeyFormPublish.deposit" @input="$forceUpdate()"/>
                </el-form-item>
                <el-form-item label="服务费(元/月)" prop="tipAmount" v-if="oneKeyFormPublish.propertyCode!==600221">
                    <el-input type="number" v-model="oneKeyFormPublish.tipAmount" @input="$forceUpdate()"/>
                </el-form-item>

            </el-form>
        </r-e-dialog>


        <!--   查看房型弹框       -->
        <layer-add-edit-publish class="layer-add-edit-publish" ref="publishRef2"/>

        <layer-bzf-publish ref="layerBzfPublish" @handleSearch="handleChange"/>
      <layeAddDailog @handleChange="handleChange" :multipleSelection="multipleSelection" ref="layeAddDailog"></layeAddDailog>
    </div>
</template>

<script>
import layeAddDailog from "@/views/rental-management/publish-apartment/components/laye-add-dailog.vue";
import {
  cancelPublish,
  getApartmentByPublishStatus,
  getCommunityListApi,
  getPublishListByCommunity, getPublishListByisLetOutRent,
  publishApartment
} from "@/api/publish";
    import {isbzf, apartmentDiscountAmountInfo,isApartmentType} from "@/api/house-resource";
    import {apartmentColumn} from "@/views/rental-management/publish-apartment/data";
    import {layerEditPublish} from "@/views/rental-management/publish-apartment/components";
    import {MessageBoxConfirm, MessageSuccess, MessageWarning, MessageError} from "@custom/message";
    import {layerAddEditPublish} from "@/views/rental-management/publish-create/components";
    import {ParseFloatFormat, ParseIntFormat} from "@custom/index";
import {bzfProjectPut, getBzfProject} from "../../../api/house-resource";
    export default {
        name: "publish-apartment",
      components: { layerEditPublish, layerAddEditPublish,
        layerBzfPublish: () => import("./components/layer-bzf-publish"),
        layeAddDailog
      },
        data() {
            return {
              projectIdNew: null,
              projectList: [],
                apartmentColumn: apartmentColumn(this),
                statuses: [{
                    id: 1,
                    name: "待发布"
                }, {
                    id: 2,
                    name: "已发布"
                }],
                communityList: [],
                list: [],
              statusList:[{uuid:"-1",name:'全部'},{uuid:'600001',name:'空置'},{uuid:'600003',name:'已租'}],
              isLetOutRentList:[{uuid:"-1",name:'全部'},{uuid:'0',name:'对外'},{uuid:'1',name:'不对外'}],
                community: "",
                formSearch: {
                    publishStatus: 1,
                    keyword:''
                },
                data: {
                    publishUuid: "",
                    apartmentUuids: []
                },
                dialogVisible: false,
                OnekeyDialogVisible: false,
                formPublish: {
                    apartmentUuid: null,
                    name: null,
                    monthAmount: null,
                    seasonAmount: null,
                    halfAmount: null,
                    yearAmount: null,
                    bone: null,
                    deposit: null,
                    tipAmount: null,
                    verificationCode:null,
                },
                rules: {
                    bone: [{required: true, message: "请输入押金", trigger: "blur"},],
                    deposit: [{required: true, message: "请输入定金", trigger: "blur"},],
                    tipAmount: [{required: true, message: "请输入服务费", trigger: "blur"},],
                    monthAmount: [{required: true, message: "请输入月租金", trigger: "blur"},],
                    seasonAmount: [{required: true, message: "请输入季租金", trigger: "blur"},],
                    halfAmount: [{required: true, message: "请输入半年租金", trigger: "blur"},],
                    yearAmount: [{required: true, message: "请输入年租金", trigger: "blur"},],
                  verificationCode: [
                    { required: true, message: '请选择是否推送', trigger: 'change' }
                  ],
                },
                multipleSelection: [],
                multipleSelectionuuid:[],
                oneKeyFormPublish: {},
                oneKeyRules: {
                    bone: [{required: true, message: "请输入押金", trigger: "blur"},],
                    deposit: [{required: true, message: "请输入定金", trigger: "blur"},],
                    tipAmount: [{required: true, message: "请输入服务费", trigger: "blur"},],
                    monthAmount: [{required: true, message: "请输入月租金", trigger: "blur"},],
                    seasonAmount: [{required: true, message: "请输入季租金", trigger: "blur"},],
                    halfAmount: [{required: true, message: "请输入半年租金", trigger: "blur"},],
                    yearAmount: [{required: true, message: "请输入年租金", trigger: "blur"},],
                },
                propertyCodeList: [],
            };
        },

        async created() {
            // 保障房 600210
            let AffordableHousing=await this.$store.dispatch("app/getDictionaryByCodeActions", "600210");
            // 长租公寓 600220
            let apartmentList=await this.$store.dispatch("app/getDictionaryByCodeActions", "600220");
            this.propertyCodeList = [...AffordableHousing,...apartmentList];
          const {msg} = await getBzfProject();
          const {result} =  JSON.parse(msg)
          // console.log(result);
          this.projectList = result;
        },
        mounted() {
            this.$refs['select'].blur();
            getCommunityListApi().then(res => {
                this.communityList = res.list;
                // console.log('res.list',res.list);
            });
            this.getList();
        },
        methods: {
          // 表格样式
          rowStyle({row, rowIndex}){
            let {pushResult} = row;

            if(pushResult  !== null){
              if(pushResult  !== '成功') return {backgroundColor:'#FFF5F5'};
            }

            // if(type===700002) return {backgroundColor:'#E5EFFF'};
            return {};
          },
          //发布
        async  fromSubmitFabu(){
          let buildTypeTye = false
          this.multipleSelection.forEach(item => {
            let result = item.name.slice(0, 6)
            if(result==='富航青年公寓')buildTypeTye = true
          })
          if(buildTypeTye){
            this.$alert('青年公寓暂不支持批量发布请重新勾选', '提示', {
              confirmButtonText: '确定',
              type: 'warning',
              callback: action => {
                // this.$message({
                //   type: 'info',
                //   message: `action: ${ action }`
                // });
              }
            });
            return
          }
          // let buildTypeArr =  this.multipleSelection.filter(item => {
          //   return item.buildType !== 1
          // })
          // console.log(buildTypeArr)
            if(this.multipleSelection.length===0) {
              MessageWarning('勾选房源不可为0')
              return
            }
            let arr = []
            this.multipleSelection.forEach(item=>{
              arr.push(item.uuid)
            })
            console.log(arr)

            let confirmittr  = false///对外
            let confirmittr2  = false//已租
            let confirmTile = '0'
            let confirmTile2 = '0'

            let itr2 = await getPublishListByCommunity({apartmentUuids:arr })
          if(itr2.info) {
            confirmittr2 = true
            confirmTile = '1'
          }

            let itr = await getPublishListByisLetOutRent({apartmentUuids:arr })
            if(itr.info) {
              confirmittr = true
              confirmTile2 = '2'
              // if(confirmTile === '1')confirmTile = '2'
              // if(confirmTile === '0')confirmTile = '1'
            }
          if( confirmTile2 === '2' ){
            if(confirmTile === '0') {
              // alert(confirmTile)

              let itr_tow = false
              this.$confirm('所选房源中含不对外房源，是否继续发布？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                // 开始
                this.$refs.layeAddDailog.title = '发布'
                this.$refs.layeAddDailog.dialogVisible = true
                this.$refs.layeAddDailog.apartmentUuids = this.multipleSelectionuuid
                this.$refs.layeAddDailog.ruleForm= {
                  projectId:'',
                  verificationCode: '',
                }
                // 结束
              }).catch(() => {
              });
            }else {
              let itr_tow = false
              this.$confirm('所选房源中含有已租房源，是否继续发布?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                // 开始
                this.$confirm('所选房源中含不对外房源，是否继续发布？', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  this.$refs.layeAddDailog.title = '发布'
                  this.$refs.layeAddDailog.dialogVisible = true
                  this.$refs.layeAddDailog.apartmentUuids = this.multipleSelectionuuid
                  this.$refs.layeAddDailog.ruleForm= {
                    projectId:'',
                    verificationCode: '',
                  }

                }).catch(() => {
                });
                // 结束
              }).catch(() => {
              });
            }
            // 对外

          }
          if( confirmTile === '1' ){
            // 对外
            let itr_tow = false
            this.$confirm('所选房源中含有已租房源，是否继续发布?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              // 开始

                this.$refs.layeAddDailog.title = '发布'
                this.$refs.layeAddDailog.dialogVisible = true
                this.$refs.layeAddDailog.apartmentUuids = this.multipleSelectionuuid
                this.$refs.layeAddDailog.ruleForm= {
                  projectId:'',
                  verificationCode: '',
                }

              // 结束
            }).catch(() => {

            });
          }

          if( confirmTile === '0' && confirmTile2 === '0'){

            console.log(this.multipleSelectionuuid)
            this.$refs.layeAddDailog.title = '发布'
            this.$refs.layeAddDailog.dialogVisible = true
            this.$refs.layeAddDailog.apartmentUuids = this.multipleSelectionuuid
            this.$refs.layeAddDailog.ruleForm= {
              projectId:'',
              verificationCode: '',
            }
          }

          // if( confirmittr2||confirmittr ){
          //   let itr_tow = false
          //   if(confirmittr2){
          //     this.$confirm('所选房源中含有已租房源，是否继续发布?', '提示', {
          //       confirmButtonText: '确定',
          //       cancelButtonText: '取消',
          //       type: 'warning'
          //     }).then(() => {
          //
          //
          //     }).catch(() => {
          //       itr_tow = true
          //     });
          //   }
          //   if(confirmittr && !itr_tow){
          //     this.$confirm('所选房源中含不对外房源，是否继续发布？', '提示', {
          //       confirmButtonText: '确定',
          //       cancelButtonText: '取消',
          //       type: 'warning'
          //     }).then(() => {
          //       console.log(this.multipleSelectionuuid)
          //       this.$refs.layeAddDailog.title = '发布'
          //       this.$refs.layeAddDailog.dialogVisible = true
          //       this.$refs.layeAddDailog.apartmentUuids = this.multipleSelectionuuid
          //       this.$refs.layeAddDailog.ruleForm= {
          //         projectId:'',
          //         verificationCode: '',
          //       }
          //     }).catch(() => {
          //     });
          //   }
          // }else {
          //   console.log(this.multipleSelectionuuid)
          //   this.$refs.layeAddDailog.title = '发布'
          //   this.$refs.layeAddDailog.dialogVisible = true
          //   this.$refs.layeAddDailog.apartmentUuids = this.multipleSelectionuuid
          //   this.$refs.layeAddDailog.ruleForm= {
          //     projectId:'',
          //     verificationCode: '',
          //   }
          // }



          },
          // 取消
        async  fromSubmitQuxiao(){
          let buildTypeTye = false
          this.multipleSelection.forEach(item => {
            let result = item.name.slice(0, 6)
            if(result==='富航青年公寓')buildTypeTye = true
          })
          if(buildTypeTye){
            this.$alert('青年公寓暂不支持批量发布请重新勾选', '提示', {
              confirmButtonText: '确定',
              type: 'warning',
              callback: action => {
                // this.$message({
                //   type: 'info',
                //   message: `action: ${ action }`
                // });
              }
            });
            return
          }
            if(this.multipleSelection.length===0) {
              MessageWarning('勾选房源不可为0')
              return
            }


          this.$refs.layeAddDailog.title = '取消'
          this.$refs.layeAddDailog.dialogVisible = true
          this.$refs.layeAddDailog.apartmentUuids = this.multipleSelectionuuid
          this.$refs.layeAddDailog.ruleForm= {
            projectId:'',
            verificationCode: '',
          }


        },
            getApartmentTableData(params) {
                return getApartmentByPublishStatus(params);
            },
            getList() {
                getApartmentByPublishStatus(this.formSearch).then(res => {
                    console.log('res',res);
                    this.list = res.list;
                });
            },
            // 批量勾选事件
            handleSelectionChange(val) {
				console.log("this.multipleSelection",val);
              let arr = []
              val.forEach(item=>{
                arr.push({apartmentUuid:item.uuid})
              })
              this.multipleSelectionuuid = arr
              console.log(this.multipleSelectionuuid)
                this.multipleSelection = val;
            },
            handleChange(isSearch = true) {
                // this.formSearch.uuid = "";
                // this.$refs.apartmentTableRef.pNumber = 1;
                this.pNumber = this.$refs.apartmentTableRef.pNumber
                console.log('this.pNumber',this.pNumber);
                this.$refs.apartmentTableRef.getTableData(isSearch);
                this.getList();
            },
            handleChange2(e) {
                let data = this.list.filter(v => v.community.uuid === e);
                console.log('data',data);
                this.$refs.apartmentTableRef.tableData = e ? data : this.list.slice(0, 10);
                this.$refs.apartmentTableRef.pNumber = 1;
                this.$refs.apartmentTableRef.total = e ? data.length : this.list.length;
            },
            handleEditPublish(row) {
                // 新增、编辑打开弹窗
                this.$refs.publishRef.layerEditPublishInit(row);
            },
          selectclick(){
            this.$refs['select'].blur();
            this.$refs['selectpublishStatus'].blur();
          },
            async handlePublish(row) {
                let {
                    propertyCode, name, amount, bone, deposit, tipAmount, uuid, monthAmount, seasonAmount, halfAmount,
                    yearAmount, insideArea, outsideArea, monthPriceAmount, yearDiscount, sixMonthDiscount
                } = row;
                const ress = await isApartmentType(uuid);
                console.log('ress',ress);
                // alert(re.info)
                // 发布打开弹窗
                if(ress.info === 0 || ress.info === 2 ) {
                    this.$refs["layerBzfPublish"].openDialog(row);
                }else{
                    this.dialogVisible = true;
                }
                monthPriceAmount = ParseFloatFormat(monthPriceAmount);

                bone = ParseFloatFormat(bone);
                deposit = ParseFloatFormat(deposit);
                tipAmount = ParseFloatFormat(tipAmount);
                monthAmount = ParseFloatFormat(monthAmount);
                seasonAmount = ParseFloatFormat(seasonAmount);
                halfAmount = ParseFloatFormat(halfAmount);
                yearAmount = ParseFloatFormat(yearAmount);

                this.formPublish = {
                    propertyCode, name, apartmentUuid: uuid, bone, deposit, tipAmount, monthAmount, seasonAmount,
                    halfAmount, yearAmount
                };

            },
            handleCancelPublish(row) {
                MessageBoxConfirm(() => {
                    cancelPublish(row.uuid).then(res => {
                        MessageSuccess(`${ res.msg }`);
                        this.handleChange(false)
                    });
                }, `确定取消发布 ${ row.name } 吗？`);
            },
            handleSubmitPublish() {
              console.log('8888')
              // alert()
                let that = this;
                // 表单提交
                this.$refs.formPublish.validate((valid) => {
                    if (valid) {
                        //that.formPublish.amount *= 100;
                        let {bone, deposit, tipAmount, monthAmount, seasonAmount, halfAmount, yearAmount} = that.formPublish;
                        bone = ParseIntFormat(bone);
                        deposit = ParseIntFormat(deposit);
                        tipAmount = ParseIntFormat(tipAmount);
                        monthAmount = ParseIntFormat(monthAmount);
                        seasonAmount = ParseIntFormat(seasonAmount);
                        halfAmount = ParseIntFormat(halfAmount);
                        yearAmount = ParseIntFormat(yearAmount);

                        that.formPublish.monthAmount = monthAmount;
                        that.formPublish.seasonAmount = seasonAmount;
                        that.formPublish.halfAmount = halfAmount;
                        that.formPublish.yearAmount = yearAmount;
                        that.formPublish.bone = bone;
                        that.formPublish.deposit = deposit;
						if(that.formPublish.propertyCode===600221) that.formPublish.tipAmount = tipAmount;
						else that.formPublish.tipAmount = 0;
                      console.log(that.formPublish)
                        publishApartment(this.formPublish).then(res => {
                            MessageSuccess(`${ this.formPublish.name } 发布成功!`);
                            that.handleCancel("update");
                        });
                    } else {
                        MessageWarning("请填写必填值");
                        return false;
                    }
                });
            },
            handleCancel(closeType = "cancel") {
                // 取消、关闭弹窗
                this.dialogVisible = false;
                // this.formPublish = {};
                this.$refs.formPublish.resetFields();
                // 新增、编辑成功，需要刷新数据
                if (closeType === "update") this.$refs.apartmentTableRef.getTableData();
            },
            onAmountInput(){
                this.$forceUpdate();
            },
            oneClickRelease(){
                let that = this;
                //2022/03/19 王江毅抽取propertyCode
                let propertyCodeS = that.multipleSelection.map(({propertyCode}) => propertyCode);
                //2022/03/19 王江毅 过滤propertyCode 60021开头的是经济保障房
                let jingji = propertyCodeS.filter(i => i.toString().startsWith("60021"));
                //2022/03/19 王江毅 过滤propertyCode 60022开头的是长租公寓
                let changzu = propertyCodeS.filter(i => i.toString().startsWith("60022"));

                console.log("经济：" + jingji.join(";"), "长租：" + changzu.join(";"));
                //2022/03/19 王江毅 两个数组都不为空说明两种类型的房源都有
                let ok = jingji.length !== 0 && changzu.length !== 0;

                //2022/03/19 王江毅 两种类型房源都有无法发布
                if (ok) MessageError("长租公寓和保障性住房不能一起发布！");
                else {
                    //2022/03/19 王江毅 过滤脏数据
                    let apartmentList = that.multipleSelection.filter(({propertyCode}) => propertyCode !== 0);
                    //2022/03/19 王江毅 抽取uuid
                    let uuids = apartmentList.map(({uuid}) => uuid);
                    console.log("uuids", uuids);
                    that.oneKeyFormPublish.apartmentUuid = uuids.join(";");
                    //2022/03/19 王江毅 changzu.length!=0说明发布的是长租公寓
                    that.oneKeyFormPublish.propertyCode = changzu.length !== 0 ? 60022 : 60021;
                    that.OnekeyDialogVisible = true;
                }
            },
            handleOneCancel(closeType = "cancel") {
                // 取消、关闭弹窗
                this.OnekeyDialogVisible = false;
                this.oneKeyFormPublish = {};
                this.$refs.formOnekeyPublish.resetFields();
                // 新增、编辑成功，需要刷新数据
                if (closeType === "update") this.$refs.apartmentTableRef.getTableData();
            },
            oneClickReleaseSubmit(){
                let that = this;
                // 表单提交
                this.$refs.formOnekeyPublish.validate((valid) => {
                    if (valid) {
                        let {apartmentUuid,monthAmount, seasonAmount, halfAmount, yearAmount, bone, deposit, tipAmount, propertyCode} = that.oneKeyFormPublish;
                        bone = ParseIntFormat(bone);
                        deposit = ParseIntFormat(deposit);
                        monthAmount = ParseIntFormat(monthAmount);
                        seasonAmount = ParseIntFormat(seasonAmount);
                        halfAmount = ParseIntFormat(halfAmount);
                        yearAmount = ParseIntFormat(yearAmount);

                        if(propertyCode===60022) tipAmount = ParseIntFormat(tipAmount);
                        else tipAmount = 0;

                        let paramsData = {apartmentUuid, monthAmount, seasonAmount, halfAmount, yearAmount, bone, deposit, tipAmount:that.formPublish.tipAmount};
                        console.log(paramsData);
                        publishApartment(paramsData).then(res => {
                            MessageSuccess(`发布成功!`);
                            that.handleOneCancel("update");
                        });
                    } else {
                        MessageWarning("请填写必填值");
                        return false;
                    }
                });
            },

        },
      watch:{
        projectIdNew(value){
          if(value){
            let {apartmentUuid: uuid} = this.formPublish;
            bzfProjectPut({uuid,projectId:value});
          }
        }
      }
    };

</script>

<style lang="scss" scoped>
     .publish-apartment {
          padding: 0 VW(15px);

          .top-bar {
               display: flex;
               margin: VH(10px) 0 VH(15px);
               padding: VH(15px) VW(15px);
               justify-content: center;
          }

          /deep/ .r-e-table {
               .el-table .cell {
                    //white-space: nowrap;
                    text-align: center;
               }
          }
     }
</style>
